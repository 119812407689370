import { Box } from '@mui/material';
import AuthFooter from 'modules/auth/AuthFooter';
import AuthHeader from 'modules/auth/AuthHeader';
import { useCallback, useEffect, useState } from 'react';
import {
  GENERAL__SEND_BUTTON,
  LOGIN__PAGE_QUOTE,
  LOGIN__CONFIRM_EMAIL_TITLE,
  GENERAL__EMAIL,
  LOGIN__RESET_PASSWORD_EMAIL_SENT,
} from 'translations/constants';
import {
  AuthContainer,
  AuthTitle,
  AuthLeftContainer,
  AuthRightContainer,
  AuthSignInContainer,
  AuthSignIn,
  AuthUPButton,
  SuccessText,
} from 'modules/auth/styles';
import { useAppDispatch, useAppSelector } from 'store';
import { authStateSelector } from 'store/selectors/auth-selectors';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import IForgotPasswordRequest from 'types/profile/IForgotPasswordRequest';
import { logoutAction } from 'store/actions/auth-actions';
import { t } from 'i18next';
import UPInput from 'components/input';
import { forgotPasswordRequestAsync } from 'store/actions/profile-actions';

const InitialState: IForgotPasswordRequest = {
  email: '',
};

const ForgotPassword = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(authStateSelector);
  const { handleSubmit, control, getValues } = useForm<IForgotPasswordRequest>({ defaultValues: InitialState });
  const [pressed, setPressed] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(logoutAction(false));
    }
  }, [dispatch, isAuthenticated]);

  const onSave: SubmitHandler<IForgotPasswordRequest> = useCallback(() => {
    const values = getValues();
    setPressed(true);
    dispatch(forgotPasswordRequestAsync({ email: values.email }));
  }, [dispatch, getValues]);

  return (
    <Box>
      <AuthHeader />
      <AuthContainer sx={{ display: 'flex' }}>
        <AuthLeftContainer>
          <AuthTitle>{t(LOGIN__PAGE_QUOTE)}</AuthTitle>
        </AuthLeftContainer>
        <AuthRightContainer>
          <AuthSignInContainer>
            <AuthSignIn>{t(LOGIN__CONFIRM_EMAIL_TITLE)}</AuthSignIn>
            <Box mt={5} sx={{ width: '100%' }}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  maxLength: 100,
                }}
                render={({ field }) => (
                  <UPInput
                    value={field.value}
                    label={t(GENERAL__EMAIL)}
                    placeholder={t(GENERAL__EMAIL)}
                    required
                    onChange={field.onChange}
                    type="email"
                  />
                )}
              />
            </Box>
            {pressed && (
              <Box>
                <SuccessText>{t(LOGIN__RESET_PASSWORD_EMAIL_SENT)}</SuccessText>
              </Box>
            )}
            <AuthUPButton
              text={t(GENERAL__SEND_BUTTON)}
              onClick={() => handleSubmit(onSave)()}
              sx={{ marginTop: '16px' }}
            />
          </AuthSignInContainer>
        </AuthRightContainer>
      </AuthContainer>
      <AuthFooter />
    </Box>
  );
};

export default ForgotPassword;
